
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Smartmenus
@import "node_modules/smartmenus-bootstrap/jquery.smartmenus.bootstrap";

body{background:#fff;}

p, li, td{ color:#fff; font-weight:400; font-family: 'Montserrat', sans-serif;  font-size:15px; line-height:1.9em;}

h1, h2, h3{margin-bottom:30px;  font-family: 'Montserrat', sans-serif; color:#fff; letter-spacing:-0.5px;  transition:all 0.3s ease; }

a{text-decoration:none !important;}
h2, h3{font-weight:100;}
h2{font-size:30px;}
h3{font-size:24px;}
a{transition:all 0.3s ease;}

.navbar-brand{height:auto;}
.navbar-inverse .navbar-toggle{background:#fe7b30; border-radius:0; margin-top:35px; border:none; }
.navbar-inverse{background:none; border:none; border-radius:0; margin:0; }
.navbar-inverse .container{border-bottom:3px solid #fe7b30; }
.navbar-inverse .navbar-nav a {font-size:12px; font-weight:500; font-family: 'Montserrat', sans-serif;  }
.navbar-inverse .navbar-nav > li > a{padding-top:45px; padding-bottom:45px; color:#fff;  }
.navbar-inverse .navbar-nav > li > a:hover{color:#fd7933;}
.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus{background:none; color:#fd7933; font-weight:bold;}
.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus{background:#fe7b30; color:#fff;}

.dropdown-menu{ border-radius:0; background:#fe7b30; padding:0;}
.dropdown-menu a{padding:20px 15px !important; color:#fff !important;}
.dropdown-menu a:hover{background:rgba(0,0,0,0.2) !important;}

.jumbotron{background:#24245b url(../img/hero_home.jpg) no-repeat center top;  margin:-114px 0 0 0; padding:160px  0 0 0;  }
.jumbotron h1{ color:#fff; font-size:38px; margin-bottom:20px; font-weight:100; }
.jumbotron h3{font-size:25px;}
.jumbotron .lead{margin-bottom:80px; color:#dddeef;}
.jumbotron .col-sm-3{}
.jumbotron_int{background-image:url(../img/hero_int.jpg); padding-bottom:40px; background-color:#262661;}
.jumbotron h1:after{content:""; display:block;  height:3px; width:50px; background:#fe7b30; margin:15px auto;}
.jumbotron .btn-primary{color:#24245b; display:block;}

.sec1 h2:after{content:""; display:block;height:3px;width:60px;background:#fe7b30; position:absolute; margin:-25px 0 0 -105px;}
.sec1{padding-bottom:0 !important;}

.homehr{display:block;  height:3px; width:60px; background:#fe7b30;}

.content_int .col-sm-4 img{margin-top:30px;}

.internal .hometextwrapper{min-height:560px;}
.internal .hometextwrapper h2{min-height:2.2em !important;}

.hometextwrapper{background:#fff; border-bottom:3px solid #25265c;  margin-top:0; overflow:hidden; padding:15px 35px; box-shadow: 0 30px 60px -30px rgba(0, 0, 0, 0.2); transition:all 0.3s ease;}
.hometextwrapper h3{ margin:20px 0 10px 0;}
.hometextwrapper h3{color:#24245b;}
.hometextwrapper p{color:#40406f !important;}
.hometextwrapper h3:before{content:""; display:block;  height:3px; width:5px; background:#fe7b30; position:absolute; margin:12px 0 0 -35px; transition:all 0.3s ease;}
.hometextwrapper:hover h3{padding-left:25px; }
.hometextwrapper:hover{}
.hometextwrapper:hover h3:before{width:25px; margin:12px 0 0 -65px;}
.hometextwrapper p{font-size:15px; color:#fff; }
.hometextwrapper h2{ margin:20px 0 10px 0;}
.hometextwrapper h2{color:#24245b; font-size:25px; }
.hometextwrapper h2:before{content:""; display:block;  height:3px; width:5px; background:#fe7b30; position:absolute; margin:12px 0 0 -35px; transition:all 0.3s ease;}

.cswrapper a{display:inline-block !important;}

.whitebg{background:#f9f9fd;}
.whitebg h2, .whitebg h3{color:#24245b; margin-bottom:15px;}
.whitebg p, .whitebg li, .whitebg td{color:#40406f;}
.whitebg .lead{color:#24245b;}
.whitebg ul{ margin:0; padding:10px 10px 10px 20px;}

.btn{border:none; border-radius:0px; padding:15px 40px; margin-top:30px; text-transform:uppercase; font-family: 'Montserrat', sans-serif;  font-weight:900; letter-spacing:1px;}
.btn-primary{background:none; border:3px solid #fe7b30; color:#fff;  box-shadow: 0 20px 60px -20px rgba(0, 0, 0, 0.3);}
.whitebg .btn-primary {color:#24245b;}
.btn-primary:hover{color:#fff; background:#fe7b30; border-color:#fe7b30;}

.internal_right .btn{display:block;}

hr{margin-top:50px;}

.gallery{padding-top:20px; padding-bottom:20px;}
.gallery img{transition:all 0.3s ease;}
.gallery img:hover{opacity:0.8;}

.breadcrumb{background:none;}
.breadcrumb a{color:#fff; font-size:13px;}
.breadcrumb > .active{color:#fff; opacity:0.6; font-size:13px;}

.contactbar p{margin-top:-25px;}
.contactbar h3{margin-top:0px !important; font-size:18px; font-weight:100;}
.contactbar h2{}
.contactbar{background: #262561 url(../img/bgcontact.jpg) no-repeat center top; padding:0 0 30px 0;}
.contactbar .container:before{width:100%; height:52px; display:block; background:url(../img/contacttop.png) repeat-x bottom; content:""; margin-bottom:20px;}

.largemap a{display:block; height:300px; text-indent:-9999px}

section{padding:80px 0 90px 0;}

footer{padding:40px 0; background:#f9f9fd; border-bottom:3px solid #fe7b30;}
footer p{color:#3938a9;}

@media(min-width:1200px){
    .dropdown-menu a{min-width:200px;}
    .contactbar .col-sm-3:first-child{width:20%;}
}
    
@media(min-width:1100px){
    body{padding:0 30px 30px 30px;}   
}

@media(min-width:768px) {
    .bottomhomesections .row{display:flex; align-items:center;}
    .navbar-brand > img{margin-left:-15px;}
}

@media(min-width:1700px) {
    .container{width:1500px;}
    body{padding:0 90px 30px 90px;}
}

@media(max-width:1550px) {
    .jumbotron h3{font-size:20px}
}

@media (max-width:1200px){   
    body{background:#353771;}
    .navbar-inverse .navbar-nav li a{padding-top:10px !important; padding-bottom:10px !important; border-bottom:1px solid rgba(255,255,255,0.1);}
    .open li a{padding-left:30px !important;}
}
    
@media (max-width:991px) {
    .contactbar{background: #262561;}
    .largemap a{border:1px solid rgba(255,255,255,0.2); background:url(../img/mapmobile.jpg) no-repeat center;}
}

@media(max-width:767px) {
    .gallery img{margin:15px 0;}
}
